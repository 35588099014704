import React from 'react';
import Gallery from './Gallery';
import './style/Content.css';

class Content extends React.Component {
    renderGallery(imagesData, hasNextSubCategory, galleryKey) {
        return (
            <Gallery
                inputData={imagesData}
                hasNextSubCategory={hasNextSubCategory}
                key={galleryKey}
            />
           )
    }

    renderVideo() {
        return (
            <div className="video-container">
                <iframe src="https://www.youtube.com/embed/tTdCLvIfgtw"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="fx video"
                />
            </div>
        );
    }

    renderContact() {
        const siteUrl = "https://tamielh.github.io/victoriakorolkova/";
        return (
            <div className="contact-container">
                <img src={siteUrl + "img/photo_profile.png"} alt="image_profile" />
                    <p>Victoria Korolkova<br/>
                    Moscou<br/>
                    <a href="mailto:korolkova.victorya@gmail.com">korolkova.victorya@gmail.com</a>
                    </p>
                <div className="buttons">
                    <a href="https://instagram.com/korolkova.victoria/" className="fa fa-instagram"/>
                    <a href="https://facebook.com/people/VictoriaKorolkova/100004453956526" className="fa fa-facebook"/>
                    <a href="https://vk.com/aletoru" className="fa fa-vk"/>
                    <br/>
                    <a href={`${window.location.href}cv_victoria.pdf`} className="fa fa-file-pdf-o"/>
                </div>
            </div>
        );
    }

    getDataByCategory(itemsData, category) {
        return itemsData.filter(itemsData => itemsData.category === category);
    }

    render() {
        const { sections, imagesData } = this.props;

        const sectionsContent = sections.map(sectionName => {
            let content;
            switch (sectionName.type) {
                case "video":
                    content = this.renderVideo();
                    break;
                case "contact":
                    content = this.renderContact();
                    break;
                case "gallery":
                    content = sectionName.key.map((key, index) =>
                        this.renderGallery(
                            this.getDataByCategory(imagesData, key),
                            index !== (sectionName.key.length - 1),
                            key
                        ));
                    break;
                default:
                    content = null;
            }

            return (
                <section key={sectionName.name} id={sectionName.name}>
                    <h1>{sectionName.name}</h1>
                    {content}
                </section>
            )
        });

        return (
            <main>
                {sectionsContent}
            </main>
        )
    };
}

export default Content;
